import React, { Component, Suspense } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./scss/style.scss";
import { history } from "./helpers";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const Header = React.lazy(() => import("./components/header"));
const Footer = React.lazy(() => import("./components/Footer"));

// Pages
const Home = React.lazy(() => import("./views/pages/home/home"));
const HositalDetalle = React.lazy(() => import("./components/HospitalDetalle"));
const Familias = React.lazy(() => import("./components/familias"));
const Profesionales = React.lazy(() => import("./components/profesionales"));
const Nosotros = React.lazy(() => import("./components/nosotros"));

const App = () => {
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <Suspense fallback={loading}>
      {history.location.pathname !== "/login" ? <Header /> : ""}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/familias" element={<Familias />} />
        <Route path="/profesionales" element={<Profesionales />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/hospital/:hospitalID" element={<HositalDetalle />} />
      </Routes>

      {history.location.pathname !== "/login" ? <Footer /> : ""}
    </Suspense>
  );
};

export default App;
