import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          global: {
            view_more: "Show more",
            loading_medical_centers: "Loading medical centers...",
            search_by_medical_center: "Search by medical center",
            search_by_city: "By city",
          },
          navigation_bar: {
            home: "Home",
            families: "Families",
            professionals: "Professionals",
            about_us: "About Us",
            contribute: "Contribute",
          },
          medical_centers_list: {
            title: "Medical Centers",
            title_description: "List of recently updated health centers",
          },
          footer: {
            description:
              "Collaborative website for virtual visits of health centers aimed at people with ASD (Autism Spectrum Disorder) and allowing the community to add information.",
            menu: "Menu",
            contact: "Contact",
          },
          us: {
            title: "Us",
            title2: "About Us",
            title3: "Collaborators",
            paragraph1:
              "We are a non-profit project aimed at helping people with ASD and their family environment.",
            paragraph2:
              "If you are a software developer and would like to collaborate, please contact us.",
            paragraph3:
              "The site provides information to patients and their families about what the places where medical practices are carried out are like in order to anticipate and understand them.",
            paragraph4:
              "In addition, it is designed so that anyone can upload hospital information, photos and videos. In this way we ensure that other families benefit from the Project. When uploading information, each person must be responsible for having the corresponding permissions to publish the information.",
            paragraph5: "You can contact us via email at:",
          },
          professionals: {
            title: "Professionals",
            title2: "How do I upload information about my hospital?",
            title3: "To be part",
            title4: "Contact",
            paragraph1:
              "The goal of the project is to generate the greatest amount of quality content for people who suffer from ASD. Helping them face new situations is what we try to avoid by allowing them to know the medical care centers in advance.",
            paragraph2:
              "There are many ways to collaborate. One of them is uploading hospital information. Both photos, videos and some descriptions that allow you to virtually visit the place. To do this we can go to the How to collaborate section?",
            paragraph3:
              "In addition, it is possible to collaborate with the project in other ways. If you are a software developer, you may be able to help us improve and grow the Project. Please, contact us.",
            paragraph4:
              "If you can think of any other way to collaborate, please contact us.",
            paragraph5:
              "To upload information, photos or videos from a hospital or health care center, you can register and access the administration panel. After uploading it, an Administrator will authorize the content and you will see it, instantly, published on the web.",
            paragraph6:
              "It is important to keep in mind that the information, photos or videos that we upload must be authorized by the health center. It is very likely that you will need to contact personnel from the institution in question.",
            paragraph7:
              "If you are a health professional, you can contribute by uploading information about the institution where you carry out your professional activity. Remember to have publishing permissions.",
          },
          families: {
            title: "Families",
            title2: "Information about ASD",
            title3: "How to collaborate?",
            title4: "Can I upload information about the nearest hospital?",
            title5: "Contact",
            paragraph1:
              "The term autism spectrum disorders (ASD) groups five clinical conditions according to the Diagnostic and Statistical Manual of Mental Disorders (DSM-IV-TR), eight if we follow the World Health Organization Classification (ICD-10).",
            paragraph2:
              "All of them are characterized by qualitative difficulties in social interaction, with a lack of empathy and social reciprocity, inability to recognize and respond to gestures and expressions, difficulties in communication and lack of flexibility in reasoning and behavior, with a restricted, stereotyped and repetitive repertoire. of activities and interests.",
            paragraph3:
              "It is a prevalent disorder, which predominates in males and whose symptoms are generally evident between 18 and 24 months of age; Despite this, it is common for delays in diagnosis to occur.",
            paragraph4:
              "Early intervention must be promoted from Primary Care by monitoring Psycho-Motor Development (PMD), carrying out systematic screening between 18-24 months of age (M-CHAT) and especially considering the concerns expressed by parents such as important warning sign. The definitive diagnosis will be carried out by a multidisciplinary team expert in ASD who will use more specific diagnostic tools. There is evidence that interventions are more effective when they are started early, between 2 and 4 years of age.",
            paragraph5:
              "In addition to the above, associated medical problems must be addressed, pharmacological and non-pharmacological interventions for problematic behaviors must be known, and training and support must be offered to families, who must be aware of the existence of controversial therapies, not supported by scientific evidence. so that they act with caution against them.",
          },
        },
      },
      es: {
        translation: {
          global: {
            view_more: "Ver más",
            loading_medical_centers: "Cargando centros médicos...",
            search_by_medical_center: "Buscar por centro médico",
            search_by_city: "Por ciudad",
          },
          navigation_bar: {
            home: "Inicio",
            families: "Familias",
            professionals: "Profesionales",
            about_us: "Nosotros",
            contribute: "Contribuir",
          },
          medical_centers_list: {
            title: "Centros de salud",
            title_description:
              "Listado de los centros de salud actualizados recientemente",
          },
          footer: {
            description:
              "Sitio web colaborativo para visita virtual de centros de salud orientado a personas con TEA (Trastornos del Espectro Autista) y permitiendo a la comunidad agregar información.",
            menu: "Menú",
            contact: "Contacto",
          },
          us: {
            title: "Nosotros",
            title2: "¿Quiénes somos?",
            title3: "Colaboradores",
            paragraph1:
              "Somos un proyecto sin fines de lucro destinado a ayudar a personas con TEA y su entorno familiar.",
            paragraph2:
              "Si usted es desarrollador de software y desea coloborar, por favor, contáctenos.",
            paragraph3:
              "El sitio proporciona información a los pacientes y sus familias sobre cómo son los lugares donde se llevan a cabo las prácticas médicas para poder anticipar y entender las mismas.",
            paragraph4:
              "Además, está diseñado para que cualquier persona pueda subir información, fotos y videos de hospitales. De esa forma logramos que otras familias se vean beneficiadas por el Proyecto. Al subir la información, cada uno debe hacerse responsable de contar con los permisos correspondientes de publicación de la información.",
            paragraph5: "Puede contactarnos vía correo electrónico a:",
          },
          professionals: {
            title: "Profesionales",
            title2: "¿Cómo subir información de mi hospital?",
            title3: "Ser parte",
            title4: "Contacto",
            paragraph1:
              "El objetivo del proyecto es generar la mayor cantidad de contenido de calidad para las personas que padecen TEA. Ayudarlos a enfrentar nuevas situaciones es lo que se intenta evitar al permitirles conocer los centros de atención médica con antelación.",
            paragraph2:
              "Existen muchas formas de colaborar. Una de ellas es subiendo información de hospitales. Tanto fotos, videos como algunas descripciones que permitan visitar de forma virtual el lugar. Para hacer podemos ir a la sección ¿Cómo colaborar?",
            paragraph3:
              "Además, es posible colaborar con el proyecto de otras maneras. Si eres desarrollador de software, es posible que nos ayudes a mejorar y hacer crecer el Proyecto. Podés ponerte en contacto con nosotros.",
            paragraph4:
              "Si se te ocurre alguna otra forma de colaborar, por favor, contactanos.",
            paragraph5:
              "Para subir información, fotos o videos de un hospital o centro de atención de salud, podés registrarte y acceder al panel de administración. Luego de subirlo, algún Administrador autorizará el contenido y lo verás, instantáneamente, publicado en la web.",
            paragraph6:
              "Es importante tener en cuenta que la información, fotos o videos que subamos deberán estar autorizados por el centro de salud. Es muy probable que debas contactarte con personal de la institución en cuestión.",
            paragraph7:
              "Si usted es un profesional de la salud puede contribuir subiendo información de la institución donde desarrolla su actividad profesional. Recuerde contar con los permisos de publicación.",
          },
          families: {
            title: "Familias",
            title2: "Información sobre TEA",
            title3: "¿Cómo colaborar?",
            title4: "¿Puedo subir información del hospital más cercano?",
            title5: "Contacto",
            paragraph1:
              "El término trastornos del espectro autista (TEA) agrupa cinco cuadros clínicos según el Manual Diagnóstico y Estadístico de los Trastornos Mentales (DSM-IV-TR), ocho si seguimos la Clasificación de la Organización Mundial de la Salud (CIE-10).",
            paragraph2:
              "Todos ellos se caracterizan por dificultades cualitativas de interacción social, con falta de empatía y reciprocidad social, incapacidad para reconocer y responder a gestos y expresiones, dificultades en la comunicación y falta de flexibilidad en razonamientos y comportamientos, con un repertorio restringido, estereotipado y repetitivo de actividades e intereses.",
            paragraph3:
              "Se trata de un trastorno prevalente, que predomina en varones y cuyos síntomas por lo general son evidentes entre los 18 y los 24 meses de edad; pese a ello es habitual que ocurra retraso en el diagnóstico.",
            paragraph4:
              "Desde Atención Primaria se ha de promover una intervención precoz mediante la vigilancia del desarrollo Psico-Motor (DPM), la realización de cribado sistemático entre los 18-24 meses de edad (M-CHAT) y considerando especialmente las preocupaciones expresadas por los padres como importante signo de alarma. El diagnóstico definitivo será llevado a cabo por un equipo multidisciplinar experto en los TEA que emplearán herramientas diagnósticas más específicas. Existe evidencia de que las intervenciones son más efectivas cuando se inician precozmente, entre los 2 y los 4 años de edad.",
            paragraph5:
              "Además de lo anterior se han de atender los problemas médicos asociados, conocer las intervenciones farmacológicas y no farmacológicas para los comportamientos problemáticos y ofrecer formación y apoyo a las familias, las cuales han de conocer la existencia de terapias controvertidas, no sustentadas en evidencias científicas, a fin de que actúen con cautela frente a las mismas.",
          },
        },
      },
    },
  });

export default i18n;
